import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import { store } from "@/store";
const routes = [
	{
		path: "/",
		redirect: "/login",
	},
	{
		path: "/login",
		name: "login",
		component: () => import(/*webpackChunkName:'login'*/ "@/views/login.vue"),
	},
	{
		path: "/home",
		name: "home",
		component: () => import("@/views/home.vue"),
		children: [
			{
				path: "/f98c07fb-50e6-4680-a536-ab40b7549deb",
				name: "f98c07fb-50e6-4680-a536-ab40b7549deb",
				component: () => import("@/views/order.vue"),
			},
			{
				path: "/77c19701-3a9b-4edc-bd00-610bbabda25d",
				name: "77c19701-3a9b-4edc-bd00-610bbabda25d",
				component: () => import("@/views/dashboard.vue"),
			},
			{
				path: "/f98c07fb-50e6-4680-a536-ab40b7549dff",
				name: "f98c07fb-50e6-4680-a536-ab40b7549dff",
				component: () => import("@/views/batch.vue"),
			},
			{
				path: "/f98c07fb-50e6-4680-a536-ab40b7549dfz",
				name: "f98c07fb-50e6-4680-a536-ab40b7549dfz",
				component: () => import("@/views/ali.vue"),
			},
			{
				path: "/f98c07fb-50e6-4680-a536-ab40b7549daa",
				name: "f98c07fb-50e6-4680-a536-ab40b7549daa",
				component: () => import("@/views/ali.vue"),
			},
			{
				path: "/f98c07fb-50e6-4680-a536-ab40b7549dab",
				name: "f98c07fb-50e6-4680-a536-ab40b7549dab",
				component: () => import("@/views/aliBatch.vue"),
			},
		],
	},
];

const router = createRouter({
	// hash模式.适合后台,不要seo
	history: createWebHashHistory(process.env.BASE_URL),
	// h5模式 适合seo
	// history: createWebHistory(process.env.BASE_URL),
	routes,
});
router.beforeEach((to, from, next) => {
	if (store.getters.GetToken) {
		if (to.path === "/login") {
			next("/home");
		} else {
			next();
		}
	} else {
		if (to.path === "/login") {
			next();
		} else {
			next("/login");
		}
	}
});
export default router;
