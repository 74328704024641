import axios from "axios";
import { ElMessage, ElLoading } from "element-plus";
import { store } from "@/store";
const axiosInstance = axios.create({
  baseURL: process.env == "development" ? "" : "",
  timeout: 13000,
});
// 预处理 请求时配置
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      "Content-Type": "application/json; charset=UTF-8",
      token: store.getters.GetToken,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const Success = 0; // 成功
const SuccessNoData = 1; // 成功
const Unauthorized = 2; // 未授权
const InvalidAuthentication = 3; // 授权失效
// 预处理 响应结果
axiosInstance.interceptors.response.use(
  (response) => {
    // 接口服务,响应结果需要保持一致
    const { Code, Msg, Data } = response.data;
    switch (Code) {
      case Success:
        response.headers["token"] && store.commit("SetToken", response.headers["token"]);
        return Promise.resolve(Data);
      case SuccessNoData:
        response.headers["token"] && store.commit("SetToken", response.headers["token"]);
        return Promise.resolve(Data);
      case InvalidAuthentication:
        ElMessage.error(Msg);
        store.dispatch("logout", false);
        return Promise.resolve(false);
      case Unauthorized:
        ElMessage.error(Msg);
        store.dispatch("logout", false);
        return Promise.resolve(false);
      default:
        ElMessage.error(Msg);
        return Promise.resolve(false);
    }
  },
  (error) => {
    ElMessage.error(error.message);
    return Promise.reject(new Error(error.message));
  }
);
const request = async (url, method, data, isLoading) => {
  let loadingInstance;
  if (isLoading) {
    loadingInstance = ElLoading.service();
  }
  let value;
  try {
    value = await axiosInstance({ url, method, data });
  } finally {
    loadingInstance && loadingInstance.close();
    return value;
  }
};
const download = async (url, data) => {
  let response = await axios.post(url, { ...data }, { responseType: "arraybuffer", headers: { token: store.getters.GetToken } });
  if (!response) {
    ElMessage.error(res.statusText);
    return;
  }
  try {
    let enc = new TextDecoder("utf-8");
    let res = JSON.parse(enc.decode(new Uint8Array(response.data)));
    switch (res.Code) {
      case Success:
        return Promise.resolve(Data);
      case InvalidAuthentication:
        ElMessage.error(res.Msg);
        return Promise.resolve(false);
      case Unauthorized:
        ElMessage.error(res.Msg);
        store.dispatch("logout", false);
        return Promise.resolve(false);
      default:
        ElMessage.error(res.Msg);
        return Promise.resolve(false);
    }
  } catch (error) {
    //application/octet-stream
    let type = response.headers["content-type"];

    let blob = new Blob([response.data], { type: type });
    let fileName = decodeURIComponent(response.headers["content-disposition"].split("=")[1]);
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }
};
const upload = async (url, data, isLoading) => {
  let loadingInstance;
  if (isLoading) {
    loadingInstance = ElLoading.service();
  }
  let res;
  try {
    res = await axios.post(url, data, { headers: { "Content-Type": "multipart/form-data", token: store.getters.GetToken } });
    const { Code, Msg, Data } = res.data;
    switch (Code) {
      case Success:
        res.headers["token"] && store.commit("SetToken", res.headers["token"]);
        return Promise.resolve(Data);
      case SuccessNoData:
        res.headers["token"] && store.commit("SetToken", res.headers["token"]);
        return Promise.resolve(Data);
      case InvalidAuthentication:
        ElMessage.error(Msg);
        store.dispatch("logout", false);
        return Promise.resolve(false);
      case Unauthorized:
        ElMessage.error(Msg);
        store.dispatch("logout", false);
        return Promise.resolve(false);
      default:
        ElMessage.error(Msg);
        return Promise.resolve(false);
    }
  } finally {
    loadingInstance && loadingInstance.close();
  }
};
const GET = (url, data, isLoading = true) => {
  return request(url, "GET", data, isLoading);
};
const POST = (url, data, isLoading = true) => {
  return request(url, "POST", data, isLoading);
};
const Download = (url, data) => {
  return download(url, data);
};
const Upload = (url, data, isLoading = true) => {
  return upload(url, data, isLoading);
};
export { GET, POST, Download, Upload };
