import { Download, POST, Upload } from "./request";

/// 通用个人操作模块
export const User = {
	Login: (data, isLoading = true) => {
		return POST("/merchant/v1/user/login", data, isLoading);
	},
	Logout: (data, isLoading = true) => {
		return POST("/merchant/v1/user/logout", data, isLoading);
	},
	ResetPass: (data, isLoading = true) => {
		return POST("/merchant/v1/user/reset-pwd", data, isLoading);
	},
	ResetCaptcha: (data, isLoading = true) => {
		return POST("/merchant/v1/user/reset-captcha", data, isLoading);
	},
};

// 订单管理

export const Order = {
	List: (data, isLoading = true) => {
		return POST("/merchant/v1/order/list", data, false);
	},
	Add: (data, isLoading = true) => {
		return POST("/merchant/v1/order/add", data, isLoading);
	},
	AddList: (data, isLoading = true) => {
		return POST("/merchant/v1/order/add-list", data, isLoading);
	},
	Edit: (data, isLoading = true) => {
		return POST("/merchant/v1/order/edit", data, isLoading);
	},
	BatchReview: (data, isLoading = true) => {
		return POST("/merchant/v1/order/batch-review", data, isLoading);
	},
	ReviewAll: (data, isLoading = true) => {
		return POST("/merchant/v1/order/review-all", data, isLoading);
	},
	TodaySuc: (data, isLoading = true) => {
		return POST("/merchant/v1/order/today-suc", data, false);
	},
	CheckBalance: (data, isLoading = true) => {
		return POST("/merchant/v1/order/check-balance", data, isLoading);
	},
	Export: (data, isLoading = true) => {
		return Download("/merchant/v1/order/export", data, isLoading);
	},
	ChannelBalance: (data, isLoading = true) => {
		return POST("/merchant/v1/order/channel-balance", data, false);
	},
	ChannelToggle: (data, isLoading = true) => {
		return POST("/merchant/v1/order/channel-toggle", data, isLoading);
	},
};
export const Batch = {
	List: (data, isLoading = true) => {
		return POST("/merchant/v1/batch/list", data, false);
	},
	Create: (data, params = {}, isLoading = true) => {
		return Upload("/merchant/v1/batch/create", data, params, isLoading);
	},
	Detail: (data, isLoading = true) => {
		return POST("/merchant/v1/batch/list-detail", data, isLoading);
	},
	Export: (data, isLoading = true) => {
		return Download("/merchant/v1/batch/list-download", data, isLoading);
	},
	ReDo: (data, isLoading = true) => {
		return POST("/merchant/v1/batch/list-edit", data, isLoading);
	},
};
// 字典数据
export const Dict = {
	BankType: (data, isLoading = true) => {
		return POST("/merchant/v1/dic/bank-type-item", data, isLoading);
	},
	OrderState: (data, isLoading = true) => {
		return POST("/merchant/v1/dic/order-state", data, isLoading);
	},
};

// dashboard
export const Dashboard = {
	Today: (data, isLoading = true) => {
		return POST("/merchant/v1/dashboard/current-data", data, isLoading);
	},
	History: (data, isLoading = true) => {
		return POST("/merchant/v1/dashboard/history-data", data, isLoading);
	},
};

export const Ali = {
	List: (data, isLoading = true) => {
		return POST("/merchant/v1/ali/list", data, false);
	},
	Add: (data, isLoading = true) => {
		return POST("/merchant/v1/ali/add", data, isLoading);
	},
	Create: (data, isLoading = true) => {
		return POST("/merchant/v1/ali-batch/create", data, isLoading);
	},
};
